<template>
    <div>
        <div>
            {{ note }}
        </div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <div class="mt-1 g-73--text text-no-wrap" v-on="on">
                    {{ shortid }} {{ version }}
                </div>
            </template>
            <span>{{ identifier }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        note() {
            return this.value?.note || '-'
        },
        version() {
            return this.value?.app_version || '?/?'
        },
        identifier() {
            return this.value?.id || ''
        },
        shortid() {
            const id = this.value?.id || ''
            return `${id.slice(0, 4)}*${id.slice(-4)}`
        },
    },
}
</script>
