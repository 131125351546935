<template>
    <v-row>
        <v-col
            cols="6"
            class="py-0 pr-1"
            :class="status === 'online' ? 'green--text' : 'red--text'"
        >
            {{ statusTxt }}
        </v-col>
        <v-col cols="6" class="py-0 px-1">
            <battery-status
                :percent="battery || 0"
                :charging="charging"
            ></battery-status>
        </v-col>
        <v-col cols="6" class="pb-0 py-1 g-73--text">
            {{ timestamp }}
        </v-col>
        <v-col cols="6" class="pb-0 pt-1 px-1 g-73--text">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div class="text-no-wrap" v-on="on">
                        <wifi-status :value="wifiSignal"></wifi-status>
                        {{ wifiSignalTxt }}dbm
                    </div>
                </template>
                <span>{{ $t('tablets.wifi_ssid') }} {{ wifiSSID }}</span>
            </v-tooltip>
        </v-col>
    </v-row>
</template>

<script>
import BatteryStatus from '../elements/BatteryStatus'
import WifiStatus from '../elements/WifiStatus'

export default {
    components: { BatteryStatus, WifiStatus },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        status() {
            return this.value?.sunmi?.sunmi_status || '-'
        },
        statusTxt() {
            return this.status?.charAt(0)?.toUpperCase() + this.status?.slice(1)
        },
        timestamp() {
            if (this.value?.sunmi?.sunmi_last_status_change) {
                return this.$date(
                    this.value?.sunmi?.sunmi_last_status_change
                ).fromNow()
            } else return '-'
        },
        battery() {
            return this.value?.battery ?? 0
        },
        charging() {
            return !!this.value?.is_charging
        },
        wifiSignal() {
            return this.value?.wifi_signal_strength ?? -99
        },
        wifiSignalTxt() {
            return this.value?.wifi_signal_strength ?? '--'
        },
        wifiSSID() {
            return this.value?.wifi_name ?? '-'
        },
    },
}
</script>
