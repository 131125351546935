<template lang="pug">
.battery.text-no-wrap(:title="`${percent} %`" :class="color")
    svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.13 142.85")
        defs
        path.cls-1(d="M257.13,92.85a14.24,14.24,0,0,1-14.29,14.29V125A17.91,17.91,0,0,1,225,142.85H17.86A17.91,17.91,0,0,1,0,125V17.86A17.91,17.91,0,0,1,17.86,0H225a17.91,17.91,0,0,1,17.86,17.86V35.71A14.24,14.24,0,0,1,257.13,50Zm-14.29,0V50H228.56V17.86A3.53,3.53,0,0,0,225,14.29H17.86a3.52,3.52,0,0,0-3.57,3.57V125a3.52,3.52,0,0,0,3.57,3.57H225a3.53,3.53,0,0,0,3.57-3.57V92.85Z")
        rect.power(x=26 y=26 :width="fillWidth" height=90)
        path.cls-2(v-show="charging" d="m 69.311902,75.21841 57.617188,21.41956 c 0.41516,0.15439 0.84774,0.23293 1.28922,0.23293 1.0207,0 1.97832,-0.41284 2.71077,-1.17469 0.72199,-0.75875 1.11897,-1.77249 1.11897,-2.85548 V 75.45018 h 42.48277 c 1.96324,0 3.60146,-1.54614 3.81194,-3.63396 0.16677,-1.85297 -0.87212,-3.57593 -2.52582,-4.19074 L 118.21021,46.209399 c -1.38479,-0.519635 -2.98626,-0.136583 -3.98529,0.913907 -0.73553,0.758752 -1.14103,1.780998 -1.14103,2.879466 V 67.39449 H 70.599575 c -1.969814,0 -3.60649,1.55039 -3.814267,3.67033 -0.01006,0.12188 -0.01548,0.23486 -0.01548,0.3579 -7.74e-4,1.70555 1.021472,3.22963 2.542071,3.79569 z" stroke-width="2" fill="black" stroke="black")
    | {{ percent }} %
</template>

<script>
export default {
    props: {
        percent: {
            type: [String, Number],
            default: null,
        },
        charging: Boolean,
    },

    computed: {
        fillWidth() {
            if (this.percent) {
                const percent = Math.max(0, Math.min(100, this.percent))
                return 190 * (percent / 100)
            }
            return 0
        },
        color() {
            return this.percent > 75
                ? 'green--text'
                : this.percent > 33
                ? 'yellow--text'
                : 'red--text'
        },
    },
}
</script>

<style lang="scss">
.battery {
    height: auto;
    width: 100%;
    font-weight: bold;

    & svg {
        width: 100%;
        max-width: 1.25em;
        fill: CurrentColor;
        margin-right: 0.25em;

        .power {
            fill: CurrentColor;
        }

        .cls-2 {
            backdrop-filter: invert(50%);
        }
    }
}
</style>
