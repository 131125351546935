<template>
    <div v-if="value.restaurants.length === 1">
        <tablet-restaurant-row
            :value="value.restaurants[0]"
        ></tablet-restaurant-row>
        <div class="mt-1 g-73--text">{{ serial }}</div>
    </div>
    <v-expansion-panels v-else-if="value.restaurants.length > 1" flat tile>
        <v-expansion-panel class="tablet-restaurant-panel">
            <v-expansion-panel-header>
                <div>
                    <div class="text-no-wrap">
                        {{ value.restaurants.length }}
                        {{ $t('tablets.x_restaurants') }}
                    </div>
                    <div class="mt-1 g-73--text">{{ serial }}</div>
                </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div
                    v-for="(restaurant, index) in value.restaurants"
                    :key="index"
                    class="mb-2"
                >
                    <tablet-restaurant-row
                        :value="restaurant"
                    ></tablet-restaurant-row>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <div v-else>
        <div>-</div>
        <div class="mt-1 g-73--text">{{ serial }}</div>
    </div>
</template>

<script>
import TabletRestaurantRow from './TabletRestaurantRow'

export default {
    components: {
        TabletRestaurantRow,
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        serial() {
            return this.value?.sn || '-'
        },
    },
}
</script>

<style lang="scss">
.tablet-restaurant-panel {
    background-color: transparent !important;

    .v-expansion-panel-header {
        padding: 0;
    }

    .v-expansion-panel-content__wrap {
        padding: 0;
    }
}
</style>
