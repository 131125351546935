<template>
    <div>
        <page-title>{{ $t('tablets.page_title') }}</page-title>

        <tablet-table></tablet-table>
    </div>
</template>

<script>
import PageTitle from '@/components/layout/components/pageTitle'
import TabletTable from '@/components/pages/tablets/Table'

export default {
    components: {
        PageTitle,
        TabletTable,
    },
}
</script>
