<template>
    <modal-dialog
        :value="dialog"
        :title="$t('tablets.actions.send_msg') + ': ' + identifier"
        :ok-text="$t('actions.send')"
        :ok-disabled="!sendable"
        :ok-loading="loading"
        cancel-button
        @cancel="$emit('close')"
        @ok="send"
    >
        <text-input v-model="title" label="labels.title"></text-input>

        <textarea-input
            v-model="message"
            label="labels.message"
        ></textarea-input>
    </modal-dialog>
</template>

<script>
import TabletModalMixin from '../mixins/TabletModalMixin'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import SunmiApiClient from '@/api/RestApi/SunmiApiClient'
import Notification from '@/services/Notification/Notification'

const defaults = {
    title: '',
    message: '',
}

export default {
    components: {
        TextareaInput,
    },
    mixins: [TabletModalMixin],
    data() {
        return {
            ...defaults,
            loading: false,
        }
    },
    computed: {
        sendable() {
            return this.title && this.message
        },
    },
    watch: {
        value: {
            handler(newVal) {
                if (!newVal) {
                    this.title = defaults.title
                    this.message = defaults.message
                }
            },
            deep: true,
        },
    },
    methods: {
        send() {
            this.loading = true
            SunmiApiClient.message(this.value.sn, this.title, this.message)
                .then(() => {
                    Notification.success(
                        this.$t('tablets.actions.send_msg') +
                            ' - ' +
                            this.value.sn,
                        this.$t('notifications.success'),
                        2000
                    )
                    this.$emit('close')
                })
                .catch((err) => {
                    Notification.error(
                        this.$t('tablets.actions.send_msg') +
                            ' - ' +
                            this.value.sn,
                        err.response?.data?.errors?.join(';')
                    )
                })
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
</script>
