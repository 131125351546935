<template>
    <div v-if="value.user_id && value.user">
        <router-link
            :to="{
                name: routeType.USER_EDIT,
                params: { userId: value.user_id },
            }"
        >
            {{ value.user.name }}
        </router-link>
        <div class="mt-1 g-73--text">{{ value.user.email }}</div>
    </div>
    <div v-else>-</div>
</template>

<script>
import routeType from '@/router/routeType'

export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        routeType: () => routeType,
    },
}
</script>
