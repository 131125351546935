<template>
    <modal-dialog
        :value="dialog"
        :title="$t('tablets.actions.edit_note') + ': ' + identifier"
        :ok-text="$t('actions.save')"
        cancel-button
        @cancel="$emit('close')"
        @ok="save"
    >
        <text-input
            v-model="innerValue"
            label="tablets.fields.note"
        ></text-input>
    </modal-dialog>
</template>

<script>
import TabletModalMixin from '../mixins/TabletModalMixin'

export default {
    mixins: [TabletModalMixin],
    data() {
        return {
            innerValue: null,
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.innerValue = newValue?.note
            },
            deep: true,
        },
    },
    methods: {
        save() {
            if (this.innerValue === this.value?.note) {
                this.$emit('close')
                return
            }

            this.$store
                .dispatch('firebase/setConfig', {
                    key: 'note',
                    id: this.value.id,
                    value: this.innerValue,
                })
                .then(() => {
                    this.$emit('close')
                })
        },
    },
}
</script>
