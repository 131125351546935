<template>
    <div class="admin-data-iterator">
        <v-row justify="space-between">
            <v-col class="pt-0">
                <table-filters
                    v-model="filters"
                    :fields="headers"
                    :list-filters="listFilters"
                ></table-filters>
            </v-col>
        </v-row>

        <overlay :show="busy">
            <d-table
                :items="items"
                :headers="headers"
                :item-class="itemClass"
                :action-width="70"
                row-action
            >
                <template v-slot:[`item.restaurant`]="{ item }">
                    <tablet-restaurant
                        :value="item"
                        class="py-1"
                    ></tablet-restaurant>
                </template>

                <template v-slot:[`item.app_status`]="{ item }">
                    <tablet-app-status
                        :value="item"
                        class="py-1"
                    ></tablet-app-status>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                    <tablet-status :value="item" class="py-1"></tablet-status>
                </template>

                <template v-slot:[`item.user`]="{ item }">
                    <tablet-user :value="item" class="py-1"></tablet-user>
                </template>

                <template v-slot:[`item.auto_accept_enabled`]="{ item }">
                    <tablet-auto-accept :value="item"></tablet-auto-accept>
                </template>

                <template v-slot:[`item.pages`]="{ item }">
                    <tablet-nr-input
                        propkey="pages"
                        min="0"
                        :value="item"
                    ></tablet-nr-input>
                </template>

                <template v-slot:[`item.volume`]="{ item }">
                    <tablet-nr-input
                        propkey="volume"
                        min="0"
                        max="10"
                        :value="item"
                    ></tablet-nr-input>
                </template>

                <template v-slot:[`item.note`]="{ item }">
                    <tablet-note :value="item"></tablet-note>
                </template>

                <template v-slot:rowActions="{ item }">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                text
                                target
                                icon
                                color="brandAlt"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item-group>
                                <v-list-item @click="itemToNoteEdit = item">
                                    <v-list-item-icon>
                                        <v-icon>edit</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{ $t('tablets.actions.edit_note') }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.sn"
                                    @click="itemToReboot = item"
                                >
                                    <v-list-item-icon>
                                        <v-icon>rotate_left</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{ $t('tablets.actions.rebooot') }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.sn"
                                    @click="itemToMessage = item"
                                >
                                    <v-list-item-icon>
                                        <v-icon>message</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{ $t('tablets.actions.send_msg') }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    v-if="item.sn"
                                    @click="itemToQuery = item"
                                >
                                    <v-list-item-icon>
                                        <v-icon>query_builder</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{
                                            $t('tablets.actions.query_realtime')
                                        }}
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="itemToDelete = item.id">
                                    <v-list-item-icon>
                                        <v-icon>delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{ $t('tablets.actions.delete') }}
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </template>
            </d-table>
        </overlay>

        <confirm-modal
            :value="!!itemToDelete"
            @onCancel="itemToDelete = null"
            @onApprove="deleteItem(itemToDelete)"
        ></confirm-modal>

        <confirm-modal
            :value="!!itemToReboot"
            @onCancel="itemToReboot = null"
            @onApprove="rebootItem(itemToReboot)"
        ></confirm-modal>

        <note-modal
            :value="itemToNoteEdit"
            @close="itemToNoteEdit = null"
        ></note-modal>

        <message-modal
            :value="itemToMessage"
            @close="itemToMessage = null"
        ></message-modal>

        <query-modal
            :value="itemToQuery"
            @close="itemToQuery = null"
        ></query-modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import isEqual from 'lodash/isEqual'
import routeType from '@/router/routeType'
import Headers from './mixins/Headers'
import ListFilters from './mixins/ListFilters'
import TableFilters from '@/components/filter/TableFilters'
import SunmiApiClient from '@/api/RestApi/SunmiApiClient'
import Notification from '@/services/Notification/Notification'
import DTable from '@/components/table/DTable'
import Overlay from '@/components/overlay/Overlay'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import TabletRestaurant from './columns/TabletRestaurant'
import TabletAppStatus from './columns/TabletAppStatus'
import TabletStatus from './columns/TabletStatus'
import TabletUser from './columns/TabletUser'
import TabletAutoAccept from './columns/TabletAutoaccept'
import TabletNrInput from './columns/TabletNrInput'
import TabletNote from './columns/TabletNote'
import NoteModal from './elements/NoteModal'
import MessageModal from './elements/MessageModal'
import QueryModal from './elements/QueryModal'

export default {
    components: {
        TableFilters,
        DTable,
        Overlay,
        ConfirmModal,
        TabletRestaurant,
        TabletAppStatus,
        TabletStatus,
        TabletUser,
        TabletAutoAccept,
        TabletNrInput,
        TabletNote,
        NoteModal,
        MessageModal,
        QueryModal,
    },
    mixins: [Headers, ListFilters],
    data() {
        return {
            busy: true,
            filters: {},
            itemToDelete: null,
            itemToNoteEdit: null,
            itemToReboot: null,
            itemToMessage: null,
            itemToQuery: null,
        }
    },
    computed: {
        ...mapGetters({ tabletClients: 'firebase/tabletClients' }),
        routeType: () => routeType,
        items() {
            return this.tabletClients.filter((item) => this.filter(item))
        },
    },
    watch: {
        filters: {
            deep: true,
            handler(newFilters, oldFilters) {
                if (isEqual(newFilters, oldFilters)) return
                if (isEqual(newFilters, this.$route.query)) return

                this.$router.replace({
                    query: newFilters,
                })
            },
        },
    },
    mounted() {
        try {
            this.$store.dispatch('firebase/init').finally(() => {
                this.busy = false
            })
            // eslint-disable-next-line no-empty
        } catch (e) {}
        this.filters = this.$route.query
    },
    beforeDestroy() {
        try {
            this.$store.dispatch('firebase/unsubscribe')
            // eslint-disable-next-line no-empty
        } catch (e) {}
    },
    methods: {
        filter(item) {
            let show = true
            if (this.filters?._search) {
                const search = this.filters._search.toLowerCase()
                // prettier-ignore
                show = show && (
                    (!!item.restaurants.find((r) => r.name.toLowerCase().includes(search))) ||
                    (item.sn || '').toLowerCase().includes(search) ||
                    (item.user?.email || '').toLowerCase().includes(search) ||
                    (item.user?.name || '').toLowerCase().includes(search)
                )
            }
            if (this.filters?.app_not_running === true) {
                let ago = this.$date().subtract(35, 'm').unix()
                show = show && item.timestamp < ago
            }
            if (this.filters?.tablet_online === true) {
                show = show && item.status === 'online'
            }
            if (this.filters?.tablet_online === false) {
                show = show && item.status !== 'online'
            }
            if (this.filters?.restaurant_active === true) {
                show =
                    show && !!item.restaurants.find((r) => r.isActive === true)
            }
            if (this.filters?.restaurant_active === false) {
                show =
                    show && !!item.restaurants.find((r) => r.isActive === false)
            }
            if (this.filters?.exclude_deliverect) {
                show =
                    show &&
                    !item.restaurants.find((r) => r.isDeliverect === true)
            }
            if (this.filters?.battery_under_50) {
                show = show && (item.battery < 50 || !item.battery)
            }

            return show
        },
        itemClass(item) {
            return item.updated ? 'updated' : ''
        },
        deleteItem(id) {
            this.$store.dispatch('firebase/deleteConfig', id)
            this.itemToDelete = null
        },
        rebootItem(item) {
            SunmiApiClient.reboot(item.sn)
                .then(() => {
                    Notification.success(
                        this.$t('tablets.actions.rebooot') + ' - ' + item.sn,
                        this.$t('notifications.success'),
                        2000
                    )
                })
                .catch((err) => {
                    Notification.error(
                        this.$t('tablets.actions.rebooot') + ' - ' + item.sn,
                        err.response?.data?.errors?.join(';')
                    )
                })
            this.itemToReboot = null
        },
    },
}
</script>

<style lang="scss">
@keyframes onupdate {
    0% {
        background-color: rgba(#007acc, 0);
    }
    30% {
        background-color: rgba(#007acc, 0.4);
    }
    100% {
        background-color: rgba(#007acc, 0);
    }
}
.updated {
    animation: onupdate 0.6s;
}
.tablet-status-header {
    min-width: 180px;
}
.tablet-autoaccept-header {
    min-width: 100px;
    width: 100px;
}
.tablet-pages-header {
    min-width: 100px;
}
</style>
