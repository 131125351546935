<template>
    <div class="d-flex justify-center">
        <v-switch
            :input-value="value.auto_accept_enabled"
            dense
            @change="onchange"
        ></v-switch>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        onchange() {
            this.$store.dispatch('firebase/setConfig', {
                key: 'auto_accept_enabled',
                id: this.value.id,
                value: !this.value.auto_accept_enabled,
            })
        },
    },
}
</script>
