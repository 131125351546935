<template>
    <modal-dialog
        :value="dialog"
        :title="$t('tablets.actions.query_realtime') + ': ' + identifier"
        :ok-button="false"
        :cancel-text="$t('actions.close')"
        cancel-button
        @cancel="$emit('close')"
    >
        <overlay :show="loading" style="min-height: 10rem">
            <div v-for="(err, idx) in error" :key="idx" class="error--text">
                {{ err }}
            </div>

            <div v-if="result">
                <v-row>
                    <v-col cols="6">{{ $t('tablets.fields.battery') }}</v-col>
                    <v-col cols="6">{{ result.battery }}%</v-col>
                    <v-col cols="6">{{ $t('tablets.fields.charging') }}</v-col>
                    <v-col cols="6">{{
                        $t(result.charging ? 'labels.yes' : 'labels.no')
                    }}</v-col>
                    <v-col cols="6">{{ $t('tablets.fields.wifi') }}</v-col>
                    <v-col cols="6">{{ result.wifi }}</v-col>
                    <v-col cols="6">{{
                        $t('tablets.fields.wifi_signal')
                    }}</v-col>
                    <v-col cols="6">{{ result.wifiSignal }}dbm</v-col>
                </v-row>
            </div>
        </overlay>
    </modal-dialog>
</template>

<script>
import TabletModalMixin from '../mixins/TabletModalMixin'
import Overlay from '@/components/overlay/Overlay'
import SunmiApiClient from '@/api/RestApi/SunmiApiClient'

export default {
    components: {
        Overlay,
    },
    mixins: [TabletModalMixin],
    data() {
        return {
            result: null,
            error: [],
            loading: false,
        }
    },
    watch: {
        value: {
            handler(newVal) {
                if (newVal) {
                    this.result = null
                    this.error = []
                    this.loading = true
                    SunmiApiClient.realtime(this.value.sn)
                        .then((res) => {
                            this.result = res
                        })
                        .catch((err) => {
                            this.error = err.response?.data?.errors || []
                        })
                        .finally(() => {
                            this.loading = false
                        })
                }
            },
        },
    },
}
</script>
