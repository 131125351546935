<template>
    <div>
        <div class="text-no-wrap" :class="colorClass">
            {{ statusTxt }}
        </div>
        <div class="mt-1 g-73--text">{{ timestamp }}</div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        status() {
            return this.value?.status
        },
        running() {
            if (this.value?.timestamp) {
                return this.$date
                    .unix(this.value.timestamp)
                    .isAfter(this.$date().subtract(35, 'm'))
            }
            return false
        },
        colorClass() {
            return this.running ? 'green--text' : 'red--text'
        },
        statusTxt() {
            let status = this.running ? this.value?.status : 'not_running'
            return this.$t(`tablets.appstatus.${status}`)
        },
        timestamp() {
            if (this.value?.timestamp) {
                return this.$date.unix(this.value.timestamp).fromNow()
            } else return '-'
        },
    },
}
</script>
