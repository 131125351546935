<template>
    <div class="d-flex justify-center">
        <v-text-field
            :value="value[propkey]"
            :hide-details="true"
            :max="max"
            :min="min"
            class="tablet-page-input"
            type="number"
            outlined
            dense
            @input="onchange"
        ></v-text-field>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        propkey: {
            type: String,
            required: true,
        },
        max: {
            type: [Number, String],
            default: undefined,
        },
        min: {
            type: [Number, String],
            default: undefined,
        },
    },
    methods: {
        onchange(newvalue) {
            this.$store.dispatch('firebase/setConfig', {
                key: this.propkey,
                id: this.value.id,
                value: parseInt(newvalue),
            })
        },
    },
}
</script>

<style>
.tablet-page-input {
    max-width: 4em;
}
</style>
