export default {
    computed: {
        headers() {
            return [
                {
                    value: 'restaurant',
                    text: this.$t('tablets.fields.restaurant'),
                    sortable: false,
                },
                {
                    value: 'app_status',
                    text: this.$t('tablets.fields.app_status'),
                    sortable: false,
                    class: 'tablet-app-status-header',
                },
                {
                    value: 'status',
                    text: this.$t('tablets.fields.status'),
                    sortable: false,
                    class: 'tablet-status-header',
                },
                {
                    value: 'user',
                    text: this.$t('tablets.fields.user'),
                    sortable: false,
                },
                {
                    value: 'pages',
                    text: this.$t('tablets.fields.pages_to_print'),
                    sortable: false,
                    class: 'tablet-pages-header',
                },
                {
                    value: 'volume',
                    text: this.$t('tablets.fields.volume'),
                    sortable: false,
                    class: 'tablet-pages-header',
                },
                {
                    value: 'auto_accept_enabled',
                    text: this.$t('tablets.fields.auto_accept_enabled'),
                    sortable: false,
                    class: 'tablet-autoaccept-header',
                },
                {
                    value: 'note',
                    text: this.$t('tablets.fields.note'),
                    sortable: false,
                },
            ]
        },
    },
}
