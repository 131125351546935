export default {
    computed: {
        listFilters() {
            return [
                {
                    key: 'app_not_running',
                    label: 'tablets.app_not_running',
                    value: true,
                    inputType: 'checkbox',
                },
                {
                    key: 'tablet_online',
                    label: 'tablets.tablet_online',
                    value: true,
                    inputType: 'checkbox',
                },
                {
                    key: 'tablet_online',
                    label: 'tablets.tablet_offline',
                    value: false,
                    inputType: 'checkbox',
                },
                {
                    key: 'restaurant_active',
                    label: 'tablets.restaurant_active',
                    value: true,
                    inputType: 'checkbox',
                },
                {
                    key: 'restaurant_active',
                    label: 'tablets.restaurant_inactive',
                    value: false,
                    inputType: 'checkbox',
                },
                {
                    key: 'exclude_deliverect',
                    label: 'tablets.exclude_deliverect',
                    value: true,
                    inputType: 'checkbox',
                },
                {
                    key: 'battery_under_50',
                    label: 'tablets.battery_under_50',
                    value: true,
                    inputType: 'checkbox',
                },
            ]
        },
    },
}
