<template>
    <div class="d-flex">
        <v-icon x-small :color="color" class="mr-1">circle</v-icon>
        <router-link
            :to="{
                name: routeType.RESTAURANT_EDIT,
                params: { id: id },
            }"
        >
            {{ name }}
        </router-link>
    </div>
</template>

<script>
import routeType from '@/router/routeType'

export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        routeType: () => routeType,
        id() {
            return this.value?.id || 0
        },
        name() {
            return this.value?.name
        },
        color() {
            return this.value?.isActive ? 'success' : 'error'
        },
    },
}
</script>
