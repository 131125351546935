import ModalDialog from '@/components/dialog/ModalDialog'
import TextInput from '@/components/form/Inputs/TextInput'

export default {
    components: {
        ModalDialog,
        TextInput,
    },
    props: {
        value: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        dialog() {
            return !!this.value
        },
        identifier() {
            return this.value?.sn
        },
    },
}
